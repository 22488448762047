body {
  background-color: #fff;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  border: solid rgb(158, 155, 155) 1px;
}

.wrap {
  display: flex;
}

.nav {
  width: 190px;
  text-align: center;
}

.link {
  display: block;
  text-decoration: none;
}

.link.active {
  text-decoration: underline;
}
